<template>
    <b-container fluid class="container_class">
        <div class="settings_wrapper">
            <div class="btn_wrapper">
                <div class="set_wrapper">
                    <div class="pvt_btn_wrapper min_width">
                        <b-button
                            class="btn_common pvt_btn   btn_color_black"
                            @click="doPivot"
                        >
                            <p class="text">
                                Pivot
                            </p>
                            <br />
                            <BIconArrowRepeat
                                icon="arrow-repeat"
                                aria-hidden="true"
                                :animation="animation"
                            ></BIconArrowRepeat>
                        </b-button>
                    </div>
                    <div class="col_row_btn_wrapper">
                        <b-button
                            class="btn_common row_col_btn min_width btn_color_white "
                            id="colBtn"
                        >
                            <p class="text">
                                Columns
                            </p>
                            <BIconPlus></BIconPlus>
                            <StandardCrosstabPopOver
                                :target="colBtn"
                                :options="colOptions"
                                @setSelectedOptions="setSelectedColOptions"
                            ></StandardCrosstabPopOver>
                        </b-button>
                        <b-button
                            class="btn_common row_col_btn min_width btn_color_white "
                            id="rowBtn"
                        >
                            <p class="text">
                                Rows
                            </p>
                            <BIconPlus></BIconPlus>
                            <StandardCrosstabPopOver
                                :target="rowBtn"
                                :options="rowOptions"
                                @setSelectedOptions="setSelectedRowOptions"
                            ></StandardCrosstabPopOver>
                        </b-button>
                    </div>
                </div>
                <div class="single_wrapper">
                    <b-button
                        class="btn_common values_btn btn_color_white"
                        id="valBtn"
                    >
                        <p class="text">
                            Values
                        </p>

                        <BIconPlus></BIconPlus>
                        <StandardCrosstabPopOver
                            :target="valBtn"
                            :options="valOptions"
                            @setSelectedOptions="setSelectedValOptions"
                        ></StandardCrosstabPopOver>
                    </b-button>
                </div>
            </div>
            <div class="selected_values_wrapper">
                <div class="selected_values_container">
                    <StandardCrosstabItem
                        v-for="itm in selectedColOptions"
                        :key="itm.value"
                        :text="itm.text"
                        :id="itm.value"
                        :isWidthDiff="false"
                        @removeSelectedItem="removeSelectedColOptions"
                    ></StandardCrosstabItem>
                </div>
                <div class="selected_values_container">
                    <StandardCrosstabItem
                        v-for="itm in selectedRowOptions"
                        :key="itm.value"
                        :text="itm.text"
                        :id="itm.value"
                        :isWidthDiff="false"
                        @removeSelectedItem="removeSelectedRowOptions"
                    ></StandardCrosstabItem>
                </div>
                <div class="selected_values_container">
                    <StandardCrosstabItem
                        v-for="itm in selectedValOptions"
                        :key="itm.value"
                        :text="itm.text"
                        :id="itm.value"
                        :isWidthDiff="true"
                        @removeSelectedItem="removeSelectedValOptions"
                    ></StandardCrosstabItem>
                </div>
            </div>
        </div>
        <div class="pivotpreview-container">
            <p class="preview_class">Preview</p>
            <div class="pivot_container">
                <vue-pivottable
                    :data="convertDate"
                    :rows="pivotValues(selectedRowOptions)"
                    :cols="pivotValues(selectedColOptions)"
                    :vals="pivotValues(selectedValOptions)"
                    aggregatorName="Sum"
                    :row-total="false"
                    :col-total="false"
                ></vue-pivottable>
            </div>
        </div>
        <div class="button-container">
            <b-button rounded @click="getCrosstabExport" class="buttonSelect"
                >Export CrossTab<img
                    src="../../assets/icon_down_circle.svg"
                    class="pl-2"
            /></b-button>
        </div>
    </b-container>
</template>

<script>
import { BIconArrowRepeat, BIconPlus } from 'bootstrap-vue';
import { datasetService } from '../../services/dataset.service';
import { commonService } from '../../services/common.service';
import _ from 'lodash';
import { VuePivottable } from 'vue-pivottable';
import 'vue-pivottable/dist/vue-pivottable.css';
import { mapMutations } from 'vuex';
import moment from 'moment';
export default {
    components: {
        BIconArrowRepeat,
        BIconPlus,
        StandardCrosstabPopOver: () => import('./StandardCrosstabPopOver.vue'),
        StandardCrosstabItem: () => import('./StandardCrosstabItem.vue'),
        VuePivottable,
    },
    props: {
        datasetId: Number,
        projectIds: String,
        dataGridData: Array,
    },
    data() {
        return {
            animation: null,
            colBtn: 'colBtn',
            rowBtn: 'rowBtn',
            valBtn: 'valBtn',
            rowOptions: [],
            colOptions: [],
            valOptions: [],
            selectedRowOptions: [],
            selectedColOptions: [],
            selectedValOptions: [],
            attributesData: [],
            toolId: 0,
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        doPivot() {
            this.animation = 'spin-pulse';
            setTimeout(() => {
                this.animation = null;
                let temp = [...this.selectedRowOptions];
                this.selectedRowOptions = [...this.selectedColOptions];
                this.selectedColOptions = [...temp];
            }, 500);
        },
        async getAttributes() {
            await this.getToolId();
            datasetService
                .getDataforCrosstab(this.datasetId, this.toolId)
                .then((res) => {
                    this.attributesData = res.data;
                    this.setInitSelectedOptions(
                        'Columns',
                        this.selectedColOptions,
                        this.colOptions,
                        'selectedColOptions'
                    );
                    this.setInitSelectedOptions(
                        'Rows',
                        this.selectedRowOptions,
                        this.rowOptions,
                        'selectedRowOptions'
                    );
                    this.setInitSelectedValueOption(
                        'Values',
                        this.selectedValOptions,
                        this.valOptions
                    );
                });
        },
        async getToolId() {
            await datasetService.getToolId('Standard Crosstab').then((res) => {
                this.toolId = res.data[0].ToolId;
            });
        },
        setInitSelectedOptions(type, selectedArray, optionArray, arrayType) {
            optionArray.push({ value: null, text: 'Please select a value' });
            this.attributesData.forEach((element) => {
                if (element.ToolAttribute.AttributeName === type) {
                    selectedArray.push({
                        value: element.DatasetColumn.DatasetColumnId,
                        text: element.DatasetColumn.ColumnName,
                        SortOrder: element.SortOrder,
                        AttributeType: element.AttributeType,
                    });
                    optionArray.push({
                        value: element.DatasetColumn.DatasetColumnId,
                        text: element.DatasetColumn.ColumnName,
                        SortOrder: element.SortOrder,
                        AttributeType: element.AttributeType,
                    });
                }
            });
            if (arrayType === 'selectedColOptions') {
                this.selectedColOptions = this.sortArray(selectedArray);
            }
            if (arrayType === 'selectedRowOptions') {
                this.selectedRowOptions = this.sortArray(selectedArray);
            }
        },
        sortArray(array) {
            let copyA = [...array];
            let defaultA = copyA.filter(
                (item) => item.AttributeType === 'Default'
            );
            let allowable = copyA
                .filter((item) => item.AttributeType === 'Allowable')
                .sort((a, b) => a.SortOrder - b.SortOrder);
            return [...defaultA, ...allowable];
        },
        setInitSelectedValueOption(type, array, optionArray) {
            optionArray.push({
                value: null,
                text: 'Please select a value',
            });
            this.attributesData.forEach((element) => {
                if (element.ToolAttribute.AttributeName === type) {
                    if (element.AttributeType === 'Default') {
                        array.push({
                            value: element.DatasetColumn.DatasetColumnId,
                            text: element.DatasetColumn.ColumnName,
                        });
                    }
                    optionArray.push({
                        value: element.DatasetColumn.DatasetColumnId,
                        text: element.DatasetColumn.ColumnName,
                    });
                }
            });
        },
        setSelectedValOptions(item) {
            if (this.selectedValOptions.find((val) => val.value === item.value))
                return;
            this.selectedValOptions = [item];
        },
        setSelectedRowOptions(item) {
            if (this.selectedRowOptions.find((val) => val.value === item.value))
                return;
            this.selectedRowOptions.push(item);
        },
        setSelectedColOptions(item) {
            if (this.selectedColOptions.find((val) => val.value === item.value))
                return;
            this.selectedColOptions.push(item);
        },
        removeSelectedColOptions(value) {
            let index = this.selectedColOptions.findIndex(
                (itm) => itm.value === value
            );

            this.selectedColOptions.splice(index, 1);
        },
        removeSelectedRowOptions(value) {
            let index = this.selectedRowOptions.findIndex(
                (itm) => itm.value === value
            );

            this.selectedRowOptions.splice(index, 1);
        },
        removeSelectedValOptions(value) {
            let index = this.selectedValOptions.findIndex(
                (itm) => itm.value === value
            );

            this.selectedValOptions.splice(index, 1);
        },

        getUTCDate(dateParm) {
            var stillUtc = moment.utc(dateParm).toDate();
            return (
                moment(stillUtc)
                    .local()
                    .format('MM/DD/yyyy') +
                ' ' +
                moment(stillUtc)
                    .local()
                    .format('LT')
            );
        },
        dataContainsDate(item) {
            const keys = Object.keys(item);
            return keys.filter((key) => item[key] instanceof Date);
        },
        async getCrosstabExport() {
            const queueService = 'Export Crosstab';
            this.setToastMessage({
                queueService: queueService,
                queueState: 'start',
                queueMessage: 'started.',
                visible: true,
            });

            let payload = {
                rows: [],
                columns: [],
                values: this.selectedValOptions[0].text,
                dataManager: JSON.parse(
                    this.$store.state.gridFilters.gridFilter
                ),
                projectid: this.projectIds,
                datasetid: this.datasetId,
            };
            payload.columns = this.selectedColOptions.map((item) => item.text);
            payload.rows = this.selectedRowOptions.map((item) => item.text);

            await datasetService
                .submitCrosstabInfo(payload)
                .then((res) => {
                    let fileName = 'Crosstab.xlsx';
                    //fileName error handling
                    try {
                        fileName =
                            res.data.ResponseMessage.Content.Headers[1]
                                .Value[0];
                    } catch (error) {
                        this.setToastMessage({
                            queueService: queueService,
                            queueState: 'error',
                            queueMessage: ' download failed.',
                            visible: true,
                        });
                    }
                    commonService.base64ToDownload(res.data.Content, fileName);
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'complete',
                        queueMessage: 'completed.',
                        visible: true,
                    });
                })
                .catch((err) => {
                    this.setToastMessage({
                        queueService: queueService,
                        queueState: 'error',
                        queueMessage: err,
                        visible: true,
                    });
                });
        },
    },
    computed: {
        convertDate() {
            const tempData = _.cloneDeep(this.dataGridData);
            const dataKeys = this.dataContainsDate(tempData[0]);
            tempData.forEach((item) => {
                dataKeys.forEach((key) => {
                    item[key] = this.getUTCDate(item[key]);
                });
            });
            return tempData;
        },
        options() {
            return (type, selectedArray) => {
                let toReturn = [{ value: null, text: 'Please select a value' }];
                this.attributesData.forEach((element) => {
                    if (element.ToolAttribute.AttributeName === type) {
                        toReturn.push({
                            value: element.DatasetColumn.DatasetColumnId,
                            text: element.DatasetColumn.ColumnName,
                        });
                    }
                });
                return _.differenceBy(toReturn, selectedArray, 'value');
            };
        },
        pivotValues() {
            return (selectedArray) => {
                return selectedArray.map((col) => col.text);
            };
        },
    },
    created() {
        this.getAttributes();
    },
};
</script>
<style lang="scss">
$settings-wrapper-height: 150px;

.settings_wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: $settings-wrapper-height;
    display: flex;
    flex-direction: row;
}

.btn_wrapper {
    position: relative;
    float: left;
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
}
.selected_values_wrapper {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.selected_values_container {
    width: 100%;
    height: 33%;
    border: 1px solid black;
    overflow: auto;
    display: flex;
    flex-direction: row;
}
.set_wrapper {
    height: 66%;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.single_wrapper {
    height: 33%;
    width: 100%;
}
.pvt_btn_wrapper {
    width: 30%;
}
.pvt_btn {
    position: relative;
    width: 100%;
    height: 100%;
    background: gray;
}
.min_width {
    min-width: 50px;
}
.btn_common {
    border-radius: 0px;
    border: 1px solid black;
}
.btn_color_black {
    color: #000;
}
.btn_color_white {
    color: white;
}
.col_row_btn_wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
}
.row_col_btn {
    width: 100%;
    height: 50%;
    margin: 0;
}
.values_btn {
    width: 100%;
    height: 100%;
}
.container_class {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 16px;
}

.pivot_container {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    float: left;
}

.preview_class {
    font-size: 20px;
    float: left;
    margin-top: 20px;
}
.pvtTable {
    width: 100%;
}

.text {
    font-size: 0.75em !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.button-container {
    display: flex;
    justify-content: flex-end;
}
@media only screen and (max-width: 1024px) {
    .btn_wrapper,
    .selected_values_wrapper {
        min-width: 120px;
    }
}
</style>
